
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="27" height="9" viewBox="0 0 27 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 4.5L7.5 8.83013V0.169873L0 4.5ZM4.6438 5.25H7.73966V3.75H4.6438V5.25ZM10.8355 5.25H12.3835V3.75H10.8355V5.25ZM12.3835 5.25H14.2105V3.75H12.3835V5.25ZM17.8647 5.25H21.5188V3.75H17.8647V5.25ZM25.1729 5.25H27V3.75H25.1729V5.25Z" fill="url(#paint0_linear_16292_192416)"/>
<defs>
<linearGradient id="paint0_linear_16292_192416" x1="1.125" y1="5" x2="27" y2="5" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ClaimTodoDashedArrow',
    inheritAttrs: true,
  }
  </script>
